import * as THREE from "three";
import { useRef, useState, useMemo, useEffect, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls, Text, Stats, Circle, Sky } from "@react-three/drei";
import randomWord from "random-words";
import {
  useGLTF,
  Environment,
  ContactShadows,
  Html,
  Float,
} from "@react-three/drei";
import soundSvg from "./sound.svg";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { Vector3 } from "three";
import { CameraControls } from "./camera-controls";
import { useSpring, animated, config } from "@react-spring/three";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  EffectComposer,
  DepthOfField,
  Vignette,
} from "@react-three/postprocessing";
import { isMobile } from "react-device-detect";
import logo from "./interspecies.svg";
import logoMantelloSvg from "./logomantello.svg";
import normals from "./normal.jpg";
import music from "./bandura.mp3";

const positionFactor = 10;

function Seed({
  position,
  color,
  navigate,
  active,
  cameraControls,
  title,
  setActiveSection,
  index,
}) {
  const ref = useRef();
  const groupRef = useRef();
  const { nodes, materials } = useGLTF("/seme2.glb");
  const normalMap = useLoader(THREE.TextureLoader, normals);

  useEffect(() => {
    groupRef.current.lookAt(new Vector3(0, 0, 0));
  });

  return (
    <group position={position} ref={groupRef}>
      <Float
        speed={1} // Animation speed, defaults to 1
        rotationIntensity={1} // XYZ rotation intensity, defaults to 1
        floatIntensity={1} // Up/down float intensity, defaults to 1
      >
        <animated.mesh
          ref={ref}
          castShadow
          receiveShadow
          geometry={nodes.Box216002.geometry}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[2, 2, 2]}
          //onClick={() => setSelected(position)}
        >
          <meshStandardMaterial
            color={color}
            roughnessMap={normalMap}
            normalMap={normalMap}
            normalScale={1}
          />
        </animated.mesh>
      </Float>
    </group>
  );
}

function Title({
  clickCallback,
  activeSection,
  setActiveSection,
  playCallback,
}) {
  const [animate, setAnimate] = useState(false);
  const [animate2, setAnimate2] = useState(false);

  useEffect(() => {
    if (activeSection === 0) {
      setTimeout(() => {
        setAnimate(true);
      });
    }

    if (activeSection === 1) {
      setTimeout(() => {
        setAnimate2(true);
        setAnimate(false);
      });
    }
  }, [activeSection]);

  return (
    <>
      <Float
        speed={1} // Animation speed, defaults to 1
        rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
        floatIntensity={0} // Up/down float intensity, defaults to 1
      >
        <mesh></mesh>
        <Html
          center
          transform
          distanceFactor={36}
          position={[0, 50, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <div
            style={{
              width: "100vw",
              height: "100vh",
              textAlign: "left",
              color: "white",
              left: 0,
              top: 0,
              zIndex: 99,
              opacity: animate ? 1 : 0,
              transition: "1s opacity",
              transitionDelay: "1.5s",
              display: activeSection === 0 ? "flex" : "none",
            }}
            className="flex items-center justify-center flex-col"
          >
            {/* <div className="absolute left-0 pl-16 top-32 max-w-2xl">
          parole come semi per coltivare il futuro{" "}
        </div> */}
            <div
              className="text-center"
              style={{ maxWidth: isMobile ? "80%" : "60%" }}
            >
              <img src={logo} width={isMobile ? "100%" : 500} />
              <div className="font-sans text-base md:text-base max-w-lg">
                Un’opera partecipata di Lisa Martignoni con gli abitanti del
                quartiere Valtesse Sant’Antonio Valverde a cura di Giovanni
                Berera e Simona Pasinelli
              </div>
            </div>
            <button
              style={{
                backgroundColor: "#fff",
                color: "#000",
                border: 0,
                padding: "8px ",
              }}
              className="mt-8 w-24 h-24 rounded-full text-white text-2xl"
              onClick={() => {
                //setActiveSection(1);
                clickCallback(false);
              }}
            >
              INIZIA
            </button>
          </div>
        </Html>
      </Float>
    </>
  );
}

export default function Intro() {
  const [activeSection, setActiveSection] = useState(0);
  const [selected, setSelected] = useState(null);
  const [soundActive, setSoundActive] = useState(false);
  const cameraControls = useRef(null);
  const audioRef = useRef();
  let navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     //cameraControls.current?.setTarget(0, 1, 0, true);
  //     cameraControls.current?.zoomTo(1.2, true);
  //   }, 1000);
  // }, [cameraControls]);

  useEffect(() => {
    controls.start({
      opacity: 0,
    });
  }, [activeSection]);

  const { rotation, position } = useSpring({
    rotation: [0, (selected * 2 * Math.PI) / 10 - Math.PI / 10, 0],
    position: activeSection === "intro" ? [0, -100, 100] : [0, 0, 120],
    config: { duration: 750 },
  });

  const controls = useAnimation();

  return (
    <div>
      <div
        className="absolute w-100 left-0 right-0 top-2 flex justify-center"
        style={{
          zIndex: 9999,
        }}
      >
        <img src={logoMantelloSvg} width={isMobile ? 160 : 180} />
      </div>
      <Canvas
        camera={{ position: [0, -5, 0.01], fov: 60 }}
        style={{ position: "absolute", width: "100vw", height: "100vh" }}
      >
        <Suspense fallback={null}>
          {/* <ambientLight intensity={0.6} /> */}
          <pointLight
            position={[-5, -50, 0]}
            angle={0.15}
            penumbra={1}
            intensity={0.85}
          />
          <CameraControls ref={cameraControls} enabled={true} speed={2} />

          <Title
            animate={true}
            clickCallback={(v) => {
              //cameraControls.current?.setTarget(0, 0, -1, true);
              controls.start({
                opacity: 1,
              });
              setTimeout(() => {
                navigate("/home");
              }, 2500);
            }}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            playCallback={() => {
              setSoundActive(true);
              audioRef.current.play();
            }}
          />

          <group>
            <Seed
              navigate={navigate}
              index={1}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 7),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 7),
              ]}
              color={"#dcc447"}
              rotation={rotation}
              title={
                "Fragilità dell'essere, petali nel vento, fiore disabitato."
              }
            />
            <Seed
              navigate={navigate}
              index={2}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 8),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 8),
              ]}
              color={"#ca902e"}
              active={selected % 10 === 1 || selected % 10 === -9}
              rotation={rotation}
              title={"Intessere relazioni, intrecciare relazioni."}
            />
            <Seed
              navigate={navigate}
              index={3}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 9),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 9),
              ]}
              color={"#c2611b"}
              rotation={rotation}
              title={"Tracce di memoria. Eredità."}
            />
            <Seed
              navigate={navigate}
              index={4}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 10),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 10),
              ]}
              color={"#d25f5f"}
              rotation={rotation}
              title={"Luoghi come radici, radicanti."}
            />
            <Seed
              navigate={navigate}
              index={5}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 11),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 11),
              ]}
              color={"#d495a3"}
              active={selected % 10 === 4 || selected % 10 === -6}
              rotation={rotation}
              title={"Costruire sogni, credere nei sogni."}
            />
            <Seed
              navigate={navigate}
              index={6}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 12),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 12),
              ]}
              color={"#a976c9"}
              active={selected % 10 === 5 || selected % 10 === -5}
              rotation={rotation}
              title={"Ceneri del cuore bruciato. Macerie."}
            />
            <Seed
              navigate={navigate}
              index={7}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 13),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 13),
              ]}
              color={"#6a7de2"}
              active={selected % 10 === 6 || selected % 10 === -4}
              rotation={rotation}
              title={"De rerum natura. Custodia del creato."}
            />
            <Seed
              navigate={navigate}
              index={8}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 14),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 14),
              ]}
              color={"#6fabe3"}
              active={selected % 10 === 7 || selected % 10 === -3}
              rotation={rotation}
              title={"Nessuno escluso. Partecipazione."}
            />
            <Seed
              navigate={navigate}
              index={9}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 15),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 15),
              ]}
              color={"#96aecd"}
              active={selected % 10 === 8 || selected % 10 === -2}
              rotation={rotation}
              title={"Calore della cura. Cura dell'altro."}
            />
            <Seed
              navigate={navigate}
              index={10}
              cameraControls={cameraControls}
              scale={[1, 1, 1]}
              setSelected={setSelected}
              position={[
                0.65 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 16),
                isMobile ? 30 : 18,
                0.65 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 16),
              ]}
              color={"#7bd1c2"}
              active={selected % 10 === 9 || selected % 10 === -1}
              rotation={rotation}
              title={"Passato, presente e futuro? Visioni di futuro."}
            />{" "}
          </group>

          <Sky azimuth={0.5} inclination={0.51} />
        </Suspense>
        <EffectComposer>
          <DepthOfField
            focusDistance={0}
            focalLength={0}
            bokehScale={2.5}
            height={480}
          />
          <Vignette eskil={false} offset={0.1} darkness={0.9} />
        </EffectComposer>
      </Canvas>

      {/* <audio autoPlay muted={!soundActive} ref={audioRef}>
        <source src={music} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <div
        className="absolute flex flex-col items-center right-2 md:right-2 top-2 cursor-pointer z-10"
        onClick={() => setSoundActive(!soundActive)}
      >
        <img
          src={soundSvg}
          width={isMobile ? 24 : 32}
          style={{
            opacity: soundActive ? 1 : 0.5,
          }}
        />
      </div> */}

      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 99999999,
          pointerEvents: "none",
        }}
        animate={controls}
        initial={{ opacity: 1 }}
        transition={{
          duration: 3,
        }}
      ></motion.div>
    </div>
  );
}
