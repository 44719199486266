import React, { useEffect, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader, BackSide } from "three";
import PropTypes from "prop-types";

const ImageSphere = ({ url, sphereRadius }) => {
  const [texture] = useLoader(TextureLoader, [url]);

  if (!url) {
    return <></>;
  }

  return (
    <mesh
      visible
      position={[0, 0, 0]}
      rotation={[0, 3.1, 0]}
      scale={[-1, 1, 1]}
      name="background"
    >
      <sphereBufferGeometry attach="geometry" args={[sphereRadius, 64, 64]} />
      {texture && (
        <meshStandardMaterial
          attach="material"
          map={texture || null}
          color={"#ffffff"}
          opacity={1}
          side={BackSide}
          depthWrite={false}
        />
      )}
    </mesh>
  );
};

export default ImageSphere;

ImageSphere.propTypes = {
  sphereRadius: PropTypes.any,
  url: PropTypes.any,
};
