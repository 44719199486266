import * as THREE from "three";
import { useRef, useState, useMemo, useEffect, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls, Text, Sparkles, Cylinder } from "@react-three/drei";
import { DoubleSide } from "three";
import { motion, useAnimation } from "framer-motion";
import { useParams } from "react-router-dom";
import { CameraControls } from "./camera-controls";
import { useSpring, animated, config } from "@react-spring/three";
import { colors, colorsOrdered } from "./utils";
import { fetchAPI } from "./api";
import audio10 from "./10.mp3";
import audio9 from "./9.mp3";
import audio8 from "./8.mp3";
import audio7 from "./7.mp3";
import audio6 from "./6.mp3";
import audio5 from "./5.mp3";
import audio4 from "./4.mp3";
import audio3 from "./3.mp3";
import audio2 from "./2.mp3";
import audio1 from "./1.mp3";
import WordController from "./WordController";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import logo from "./interspecies.svg";
import pattern from "./pattern.jpg";

const positionFactor = 10;

function Word({ children, ...props }) {
  const { isSelected, setSelected, index, position, selectedPos, description } =
    props;

  const color = new THREE.Color();
  const fontProps = {
    font: "/MadeofScars-Regular.woff",
    fontSize: isMobile ? 0.8 : description ? 1.3 : 1,
    letterSpacing: 0,
    lineHeight: 1,
    "material-toneMapped": true,
  };

  const ref = useRef();
  const [hovered, setHovered] = useState(false);
  const over = (e) => (e.stopPropagation(), setHovered(true));
  const out = () => setHovered(false);

  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered && description) document.body.style.cursor = "pointer";
    return () => (document.body.style.cursor = "auto");
  }, [hovered]);
  // Tie component to the render-loop
  useFrame(({ camera }) => {
    // Make text face the camera
    ref.current.lookAt(0, 0, 0);
    //ref.current.quaternion.copy(camera.quaternion);
    // Animate font color
    // ref.current.position.lerp(
    //   isSelected === index ? selectedPos : position,
    //   0.1
    // );

    ref.current.material.color.lerp(
      color.set(hovered ? props.color : "#ffffff"),
      0.5
    );
    ref.current.material.transparent = true;
    ref.current.material.opacity = description ? 1 : 0.5;
  });
  return (
    <group>
      <Text
        ref={ref}
        onPointerOver={over}
        onPointerOut={out}
        onClick={() => {
          if (description) {
            setSelected(isSelected === index ? null : index);
          }
        }}
        {...props}
        {...fontProps}
        children={children}
      />
    </group>
  );
}

function Cloud({
  count = 4,
  radius = 20,
  color,
  setSelected,
  selected,
  wordsList,
}) {
  //console.log({ wordsList });
  // Create a count x count random words with spherical distribution
  const words = useMemo(() => {
    const temp = [];

    const columns = 8;
    const rows = 4;

    let count = -1;

    for (let i = 0; i < rows + 1; i++) {
      for (let j = 0; j < columns; j++) {
        const angle = ((Math.PI * 2) / columns) * j + i * (Math.PI / 4);
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);
        const z = i * 2 - 2;

        count += 1;

        //console.log(count);

        if (wordsList[count]) {
          temp.push([
            new THREE.Vector3(x, z, y),
            wordsList[count].name,
            wordsList[count].description,
          ]);
        }
      }
    }

    // for (let i = 1; i < count + 1; i++)
    //   // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
    //   for (let j = 0; j < count; j++)
    //     temp.push([
    //       new THREE.Vector3().setFromSpherical(
    //         spherical.set(radius, phiSpan * i, thetaSpan * j)
    //       ),
    //       randomWord(),
    //     ]);
    return temp;
  }, [wordsList, radius]);
  return words.map(([pos, word, description], index) => (
    <Word
      key={index}
      index={index}
      position={pos}
      children={word}
      description={description}
      color={color}
      setSelected={setSelected}
      isSelected={selected}
    />
  ));
}

export default function Words() {
  const [activeSection, setActiveSection] = useState("intro");
  const [words, setWords] = useState([]);
  const [fileAudio, setFileAudio] = useState(null);
  const [selected, setSelected] = useState(null);
  const [portion, setPortion] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const cameraControls = useRef(null);
  const controls = useAnimation();
  const controls2 = useAnimation();
  let { id } = useParams();
  const [soundActive, setSoundActive] = useState(true);
  const color = colors[Number(id) - 1];
  const textureMap = useLoader(THREE.TextureLoader, pattern);
  //textureMap.format = THREE.RGBAFormat;
  textureMap.wrapS = THREE.RepeatWrapping;
  textureMap.wrapT = THREE.RepeatWrapping;
  textureMap.repeat.set(-5, 1);
  let navigate = useNavigate();
  const audioRef = useRef();

  useEffect(() => {
    if (selected) {
      controls2.start({
        opacity: 0,
        transition: {
          duration: 2,
        },
      });
    }
  }, [selected]);

  useEffect(() => {
    controls.start({
      opacity: 0,
      transition: {
        duration: 2,
      },
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      const data = await fetchAPI(`/seeds/${id}`);
      // convert the data to json
      // set state with the result
      console.log(data.words);
      setWords(data.words);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    if (id) {
      if (id === "1") {
        setFileAudio(audio1);
      } else if (id === "2") {
        setFileAudio(audio2);
      } else if (id === "3") {
        setFileAudio(audio3);
      } else if (id === "4") {
        setFileAudio(audio4);
      } else if (id === "5") {
        setFileAudio(audio5);
      } else if (id === "6") {
        setFileAudio(audio6);
      } else if (id === "7") {
        setFileAudio(audio7);
      } else if (id === "8") {
        setFileAudio(audio8);
      } else if (id === "9") {
        setFileAudio(audio9);
      } else if (id === "10") {
        setFileAudio(audio10);
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      //cameraControls.current?.setTarget(0, 1, 0, true);
      cameraControls.current?.zoomTo(1.1, true);
    }, 1000);
  }, [cameraControls]);

  //fetchAPI("/articles?_sort=published_at:DESC"),

  const { rotation } = useSpring({
    rotation: [0, (portion * 2 * Math.PI) / 8, 0],
  });

  const toggleSound = () => {
    setSoundActive((state) => !state);
  };

  return (
    <>
      <div
        className="absolute w-100 left-0 right-0 top-2 flex justify-center text-white text-2xl md:text-4xl"
        style={{
          zIndex: 8,
        }}
      >
        <img src={logo} width={isMobile ? 160 : 200} />
      </div>
      <Canvas
        camera={{ position: [0, 0, 2], fov: 50 }}
        style={{ position: "absolute", width: "100vw", height: "100vh" }}
      >
        <Suspense fallback={null}>
          <fog attach="fog" args={["#555", 10, 50]} />

          <pointLight intensity={0.7} position={[0, 5, 0]} decay={1} />
          <spotLight intensity={0.5} color={color} penumbra={0.5} />

          <CameraControls ref={cameraControls} enabled={false} />

          <animated.group rotation={rotation}>
            <Cylinder args={[36, 36, 64, 32]}>
              <meshStandardMaterial
                color={color}
                side={DoubleSide}
                // map={textureMap}
                // alphaMap={textureMap}
                // transparent={true}
                // alphaTest={0.1}
              />
            </Cylinder>
            <Cloud
              count={7}
              radius={20}
              color={color}
              setSelected={setSelected}
              selected={selected}
              wordsList={words}
            />
            <Sparkles
              /** Number of particles (default: 100) */
              count={1000}
              /** Speed of particles (default: 1) */
              speed={0.1}
              /** Opacity of particles (default: 1) */
              opacity={0.4}
              /** Color of particles (default: 100) */
              color={new THREE.Color("#fff")}
              /** Size of particles (default: randomized between 0 and 1) */
              size={1}
              /** The space the particles occupy (default: 1) */
              scale={10}
              /** Movement factor (default: 1) */
              noise={2}
            />
          </animated.group>
        </Suspense>
      </Canvas>
      {selected !== null && (
        <motion.div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: "100vw",
            minHeight: "100vh",
            zIndex: 9999,
            color: "white",
            display: "flex",
          }}
          animate={{ backgroundColor: "rgba(0,0,0,.85)", opacity: 1 }}
          initial={{ backgroundColor: "rgba(0,0,0,0)", opacity: 0 }}
          transition={{ duration: 1 }}
          className="p-8 md:px-36 text-md md:text-xl text-justify flex justify-center "
        >
          <div className="w-full max-w-lg md:max-w-3xl mx-auto">
            <div className="text-5xl md:text-7xl py-4 text-center break-words	">
              {words[selected].name}
            </div>
            {words[selected].description && (
              <p
                className="font-sans py-6 text-base md:text-xl text-center"
                dangerouslySetInnerHTML={{
                  __html: words[selected].description.replaceAll("\n", "<br/>"),
                }}
              ></p>
            )}
            <div
              className="flex justify-center text-7xl py-12 cursor-pointer"
              onClick={() => setSelected(null)}
            >
              X
            </div>
          </div>
        </motion.div>
      )}

      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 999,
          pointerEvents: "none",
        }}
        animate={controls}
        initial={{ opacity: 1 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      ></motion.div>
      {fileAudio && (
        <audio ref={audioRef} muted={!soundActive}>
          <source src={`${fileAudio}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      <WordController
        prevCallback={() => {
          setPortion(portion - 1);
        }}
        nextCallback={() => {
          setPortion(portion + 1);
        }}
        playCallback={() => {
          if (!isPlaying) {
            audioRef.current.play();
            setIsPlaying(true);
          } else {
            audioRef.current.pause();
            setIsPlaying(false);
          }
        }}
        toggleSound={toggleSound}
        color={color}
        soundActive={soundActive}
        isPlaying={isPlaying}
        closeCallback={() => {
          controls.start({
            opacity: 1,
            transition: {
              duration: 2,
            },
          });
          setTimeout(() => {
            navigate("/home");
          }, 1500);
        }}
      />
    </>
  );
}
