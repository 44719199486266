export const colorsOrdered = [
  "#D3585A",
  "#B64A99",
  "#6D40AE",
  "#5553E2",
  "#96AEFF",
  "#93A48B",
  "#74A441",
  "#C99F18",
  "#BE6600",
  "#C24E26",
];

export const colors = [
  "#BE6600", //1 cura
  "#5553E2", //2 eredità
  "#D3585A", //3 fragilita
  "#C24E26", //4 futuro
  "#93A48B", //5 macerie
  "#6d40ae", //6 memoria
  "#74A441", //7 natura
  "#C99F18", //8 partecipazione
  "#b64a99", //9 relazioni
  "#96AEFF", //10 sogni
];
