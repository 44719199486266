import * as THREE from "three";
import { useRef, useState, useEffect, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { useGLTF, Html, Float } from "@react-three/drei";
import { motion, useAnimation } from "framer-motion";
import { Vector3 } from "three";
import { CameraControls } from "./camera-controls";
import { useSpring, animated } from "@react-spring/three";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { colors, colorsOrdered } from "./utils";
import { EffectComposer, Vignette } from "@react-three/postprocessing";
import bg from "./mantello_valtesse_background.jpg";
import ImageSphere from "./ImageSphere";
import AppControllers from "./AppControllers";
import logo from "./interspecies.svg";
import normals from "./normal.jpg";
import title1 from "./titolo1.svg";
import title2 from "./titolo2.svg";
import title3 from "./titolo3.svg";
import title4 from "./titolo4.svg";
import title5 from "./titolo5.svg";
import title6 from "./titolo6.svg";
import title7 from "./titolo7.svg";
import title8 from "./titolo8.svg";
import title9 from "./titolo9.svg";
import title10 from "./titolo10.svg";
import music from "./bandura.mp3";

const positionFactor = 10;

const seedIndex = [3, 9, 6, 2, 10, 5, 7, 8, 1, 4];

function Seed({ position, color, active, title }) {
  const ref = useRef();
  const groupRef = useRef();
  const { nodes, materials } = useGLTF("/seme2.glb");
  const [activeInternal, setActiveInternal] = useState(false);
  const normalMap = useLoader(THREE.TextureLoader, normals);

  useEffect(() => {
    groupRef.current.lookAt(new Vector3(0, 0, 0));
  }, []);

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setActiveInternal(true);
      }, 1000);
    }
  }, [active]);

  return (
    <group position={position} ref={groupRef}>
      <Float
        speed={1} // Animation speed, defaults to 1
        rotationIntensity={1} // XYZ rotation intensity, defaults to 1
        floatIntensity={1} // Up/down float intensity, defaults to 1
      >
        <animated.mesh
          ref={ref}
          castShadow
          receiveShadow
          geometry={nodes.Box216002.geometry}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[6, 6, 12]}
          //onClick={() => setSelected(position)}
        >
          <meshStandardMaterial
            color={color}
            roughnessMap={normalMap}
            normalMap={normalMap}
            normalScale={1}
          />
        </animated.mesh>
      </Float>
      {active && (
        <Html center position={[0, 0, 0]} zIndexRange={[2]}>
          <div
            className="flex flex-col items-center pointer-events-none seed-title"
            style={{
              width: isTablet ? "50vw" : isMobile ? "90vw" : "60vw",
              opacity: activeInternal ? 1 : 0,
              transition: ".5s opacity",
              transitionDelay: "0.3s",
              msTransitionTimingFunction: "ease-in",
            }}
          >
            {/* <div className="text-5xl md:text-6xl max-w-lg text-center text-white">
              {title || "TEMA"}{" "}
            </div> */}
            <img src={title} className="w-full md:w-3/4 pointer-events-none" />
          </div>
        </Html>
      )}
    </group>
  );
}

const AnimationCamera = () => {
  const vec = new Vector3();

  useFrame((state) => {
    state.camera.position.lerp(vec.set(0, 12, 0), 0.01);
    state.camera.updateProjectionMatrix();
  });

  return <></>;
};

export default function App() {
  const [activeSection, setActiveSection] = useState(null);
  const [selected, setSelected] = useState(null);
  const [activeSeed, setActiveSeed] = useState(0);
  const [soundActive, setSoundActive] = useState(true);
  const [showIntro] = useState(localStorage.getItem("showIntro"));
  const cameraControls = useRef(null);
  const audioRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    controls.start({
      opacity: 0,
      transition: {
        duration: 2,
      },
    });

    const lastSeed = localStorage.getItem("activeSeed");

    if (lastSeed) {
      setActiveSeed(parseInt(lastSeed));
      setSelected(parseInt(lastSeed));
    }

    // setTimeout(() => {
    //   cameraControls.current?.rotate(Math.PI / 4, 0, true);
    //   cameraControls.current?.setLookAt(0, 0, 0, 0, 0, -1, true);
    // }, 1000);
  }, []);

  useEffect(() => {
    if (activeSection === "selected") {
      controls.start({
        opacity: 1,
        transition: {
          duration: 2,
        },
      });
    }
  }, [activeSection]);

  useEffect(() => {
    if (selected % 10 === 0) {
      controls2.start({
        backgroundColor: colors[2],
        transition: {
          duration: 2,
        },
      });
    }

    if (selected % 10 === 1 || selected % 10 === -9) {
      controls2.start({
        backgroundColor: colors[8],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 2 || selected % 10 === -8) {
      controls2.start({
        backgroundColor: colors[5],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 3 || selected % 10 === -7) {
      controls2.start({
        backgroundColor: colors[1],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 4 || selected % 10 === -6) {
      controls2.start({
        backgroundColor: colors[9],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 5 || selected % 10 === -5) {
      controls2.start({
        backgroundColor: colors[4],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 6 || selected % 10 === -4) {
      controls2.start({
        backgroundColor: colors[6],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 7 || selected % 10 === -3) {
      controls2.start({
        backgroundColor: colors[7],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 8 || selected % 10 === -2) {
      controls2.start({
        backgroundColor: colors[0],
        transition: {
          duration: 2,
        },
      });
    }
    if (selected % 10 === 9 || selected % 10 === -1) {
      controls2.start({
        backgroundColor: colors[3],
        transition: {
          duration: 2,
        },
      });
    }
  }, [selected]);

  const { rotation, position } = useSpring({
    rotation: [0, (selected * 2 * Math.PI) / 10 - Math.PI / 10, 0],
    position: activeSection === "intro" ? [0, -100, 120] : [0, 2, 120],
    config: { duration: 750 },
  });

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  const toggleSound = () => {
    setSoundActive((state) => !state);
  };

  return (
    <div>
      <div
        className="absolute w-100 left-0 right-0 top-2 flex justify-center text-white text-2xl md:text-4xl"
        style={{
          zIndex: 8,
        }}
      >
        <img src={logo} width={isMobile ? 160 : 200} />
      </div>
      <Canvas
        camera={{ position: [0, 20, 50], fov: 60 }}
        style={{ position: "absolute", width: "100vw", height: "100vh" }}
      >
        <Suspense fallback={null}>
          <AnimationCamera />
          <ambientLight intensity={0.3} />
          <pointLight
            position={[0, 30, 0]}
            angle={0.15}
            penumbra={1}
            shadow-mapSize={[512, 512]}
            castShadow
            intensity={0.5}
          />

          <animated.group rotation={rotation} position={position}>
            <ImageSphere url={bg} sphereRadius={800} />
            {activeSeed === 0 && (
              <Seed
                navigate={navigate}
                index={3}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 7),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 7),
                ]}
                color={colors[3 - 1]}
                active={selected % 10 === 0}
                rotation={rotation}
                setActiveSection={setActiveSection}
                i={0}
                title={title1}
              />
            )}
            {activeSeed === 1 && (
              <Seed
                navigate={navigate}
                index={9}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 8),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 8),
                ]}
                color={colors[9 - 1]}
                active={selected % 10 === 1 || selected % 10 === -9}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Intessere relazioni, intrecciare relazioni."}
                i={1}
                title={title2}
              />
            )}
            {activeSeed === 2 && (
              <Seed
                navigate={navigate}
                index={6}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 9),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 9),
                ]}
                color={colors[6 - 1]}
                active={selected % 10 === 2 || selected % 10 === -8}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Nessuno escluso. Partecipazione."}
                i={2}
                title={title3}
              />
            )}
            {activeSeed === 3 && (
              <Seed
                navigate={navigate}
                index={2}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 10),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 10),
                ]}
                color={colors[2 - 1]}
                active={selected % 10 === 3 || selected % 10 === -7}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Calore della cura. Cura dell'altro."}
                i={3}
                title={title4}
              />
            )}
            {activeSeed === 4 && (
              <Seed
                navigate={navigate}
                index={10}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 11),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 11),
                ]}
                color={colors[10 - 1]}
                active={selected % 10 === 4 || selected % 10 === -6}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Costruire sogni, credere nei sogni."}
                i={4}
                title={title5}
              />
            )}
            {activeSeed === 5 && (
              <Seed
                navigate={navigate}
                index={5}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 12),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 12),
                ]}
                color={colors[5 - 1]}
                active={selected % 10 === 5 || selected % 10 === -5}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Ceneri del cuore bruciato. Macerie."}
                i={5}
                title={title6}
              />
            )}
            {activeSeed === 6 && (
              <Seed
                navigate={navigate}
                index={7}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 13),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 13),
                ]}
                color={colors[7 - 1]}
                active={selected % 10 === 6 || selected % 10 === -4}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"De rerum natura. Custodia del creato."}
                i={6}
                title={title7}
              />
            )}
            {activeSeed === 7 && (
              <Seed
                navigate={navigate}
                index={8}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 14),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 14),
                ]}
                color={colors[8 - 1]}
                active={selected % 10 === 7 || selected % 10 === -3}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Tracce di memoria. Eredità."}
                i={7}
                title={title8}
              />
            )}
            {activeSeed === 8 && (
              <Seed
                navigate={navigate}
                index={1}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 15),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 15),
                ]}
                color={colors[1 - 1]}
                active={selected % 10 === 8 || selected % 10 === -2}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Luoghi come radici. Radicati."}
                i={8}
                title={title9}
              />
            )}
            {activeSeed === 9 && (
              <Seed
                navigate={navigate}
                index={4}
                cameraControls={cameraControls}
                scale={[1, 1, 1]}
                setSelected={setSelected}
                position={[
                  15 * positionFactor * Math.cos(((2 * Math.PI) / 10) * 16),
                  isMobile ? 2 : 1,
                  15 * positionFactor * Math.sin(((2 * Math.PI) / 10) * 16),
                ]}
                color={colors[4 - 1]}
                active={selected % 10 === 9 || selected % 10 === -1}
                rotation={rotation}
                setActiveSection={setActiveSection}
                //title={"Passato, presente e futuro? Visioni di futuro."}
                i={9}
                title={title10}
              />
            )}
          </animated.group>
        </Suspense>
        <EffectComposer>
          <Vignette eskil={false} offset={0.1} darkness={0.9} />
        </EffectComposer>
      </Canvas>
      <audio loop autoPlay ref={audioRef} muted={!soundActive}>
        <source src={music} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      {!showIntro && (
        <motion.div
          style={{
            width: "100vw",
            height: "100vh",
            textAlign: "left",
            color: "white",
            left: 0,
            top: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,.8)",
            position: "absolute",
            pointerEvents: activeSection === null ? "all" : "none",
          }}
          className="flex items-center justify-center flex-col"
          animate={controls3}
          initial={{ opacity: 1 }}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
        >
          <div
            className="text-base md:text-base text-center font-sans"
            style={{ maxWidth: isMobile ? "80%" : "30%" }}
          >
            <span className="text-lg md:text-3xl font-sans">
              I semi sono storie che germogliano quando vengono raccontate.
            </span>
            <br />
            <br /> Il processo di arte partecipata condotto da Lisa Martignoni
            con gli abitanti del quartiere Valtesse Sant’Antonio Valverde trova
            compimento nell’installazione Interspecies.
            <br />
            Dieci grandi sculture tessili, in cui materia e parole, colori e
            alfabeti si interconnettono, svelando la ricchezza celata nel
            tessuto sociale che anima il quartiere.
            <br />
            <br /> I dieci semi suggeriscono storie e custodiscono parole, che
            potranno diventare realtà, se troveranno voce e ascolto. Se qualcuno
            li coltiverà.
          </div>
          <button
            style={{
              backgroundColor: "#fff",
              color: "#000",
              border: 0,
              padding: "8px ",
            }}
            className="mt-8 w-24 h-24 rounded-full text-white text-2xl"
            onClick={() => {
              controls3.start({
                opacity: 0,
                transition: {
                  duration: 2,
                },
              });
              setActiveSection("selection");
              audioRef.current.play();
              localStorage.setItem("showIntro", true);
            }}
          >
            AVANTI
          </button>
        </motion.div>
      )}

      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 20,
          pointerEvents: "none",
          opacity: 0.2,
        }}
        animate={controls2}
        initial={{ backgroundColor: "red" }}
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      ></motion.div>
      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 999,
          pointerEvents: "none",
        }}
        animate={controls}
        initial={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
      ></motion.div>

      <AppControllers
        active={activeSection === "intro"}
        enterCallback={() => {
          setActiveSection("selected");
          //cameraControls.current?.zoomTo(10, true);
          //cameraControls.current?.setLookAt(0, 0, 0, 0, 0, 0, true);
          setTimeout(() => {
            navigate("/tema/" + seedIndex[activeSeed]);
          }, 1500);
        }}
        prevCallback={() => {
          setSelected(selected - 1);
          setActiveSeed(activeSeed < 1 ? 9 : activeSeed - 1);
          localStorage.setItem(
            "activeSeed",
            activeSeed < 1 ? 9 : activeSeed - 1
          );
          if (audioRef.current && audioRef.current.paused) {
            audioRef.current.play();
          }
        }}
        nextCallback={() => {
          setSelected(selected + 1);
          setActiveSeed(activeSeed > 8 ? 0 : activeSeed + 1);
          localStorage.setItem(
            "activeSeed",
            activeSeed > 8 ? 0 : activeSeed + 1
          );
          if (audioRef.current && audioRef.current.paused) {
            audioRef.current.play();
          }
        }}
        color={colorsOrdered[activeSeed]}
        activeSeed={activeSeed}
        toggleSound={toggleSound}
        soundActive={soundActive}
      />
    </div>
  );
}
