import { useEffect } from "react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { isMobile } from "react-device-detect";
import closeSvg from "./close.svg";
import playSvg from "./play.svg";
import pauseSvg from "./pause.svg";

const WordController = ({
  active,
  playCallback,
  prevCallback,
  nextCallback,
  toggleSound,
  soundActive,
  closeCallback,
  isPlaying,
  color,
}) => {
  return (
    <>
      <div
        className="absolute bottom-8 md:bottom-24 w-80 md:w-96 bg-none rounded-full px-6 md:px-8 left-0 right-0 mx-auto flex flex-col items-center z-30"
        style={{
          height: "160px",
        }}
      >
        <div className="flex items-center justify-between">
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 1.2 }}
            style={{
              pointerEvents: active ? "none" : "all",
              opacity: active ? 0 : 1,
              zIndex: 9999,
              cursor: "pointer",
            }}
            onClick={() => {
              prevCallback();
            }}
          >
            <img src={"/left.svg"} width={isMobile ? 36 : 36} />
          </motion.div>
          <motion.div className="flex items-center justify-center mx-8">
            <button
              style={{
                backgroundColor: color,
                border: 0,
                padding: "8px ",
                //boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
              }}
              className="w-24 h-24 rounded-full text-white text-2xl flex items-center justify-center"
              onClick={() => {
                playCallback();
              }}
            >
              <motion.img
                src={isPlaying ? pauseSvg : playSvg}
                width={isMobile ? 45 : 45}
                style={{
                  opacity: soundActive ? 1 : 0.5,
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1.2 }}
              />
            </button>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 1.2 }}
            style={{
              pointerEvents: active ? "none" : "all",
              opacity: active ? 0 : 1,
              zIndex: 9999,
              cursor: "pointer",
            }}
            onClick={() => {
              nextCallback();
            }}
          >
            <img src={"/right.svg"} width={isMobile ? 36 : 36} />
          </motion.div>
        </div>
        <div className="font-sans text-white text-center mt-4 text-xs md:text-sm">
          Ascolta il racconto, spostati con le frecce ed esplora le parole,
          toccale e scopri di più
        </div>
      </div>

      <div
        className="absolute flex flex-col items-center right-2 top-2 cursor-pointer z-10"
        onClick={closeCallback}
      >
        <motion.img
          src={closeSvg}
          width={isMobile ? 32 : 32}
          style={{
            opacity: soundActive ? 1 : 0.5,
          }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 1.2 }}
        />
        <div
          className="text-white font-sans mt-2"
          style={{
            fontSize: "11px",
          }}
        >
          HOME
        </div>
      </div>
    </>
  );
};

export default WordController;
