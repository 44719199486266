import { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { isMobile } from "react-device-detect";
import soundSvg from "./sound.svg";
import aboutSvg from "./about.svg";
import closeSvg from "./close.svg";
import logo from "./interspecies.svg";
import logoMantelloSvg from "./logomantello.svg";

const AppControllers = ({
  active,
  enterCallback,
  homeCallback,
  color = null,
  prevCallback,
  nextCallback,
  toggleSound,
  soundActive,
  activeSeed,
}) => {
  const [aboutOpen, setAboutOpen] = useState(false);
  const controls = useAnimation();
  return (
    <>
      <div
        className="absolute bottom-2 lg:bottom-8 md:bottom-16 w-80 md:w-96 bg-none rounded-full px-6 md:px-8 left-0 right-0 mx-auto flex flex-col items-center z-30"
        style={{
          height: "200px",
        }}
      >
        <div className="flex items-center justify-between">
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 1.2 }}
            style={{
              pointerEvents: active ? "none" : "all",
              opacity: active ? 0 : 1,
              zIndex: 9999,
              cursor: "pointer",
            }}
            onClick={() => {
              prevCallback();
            }}
          >
            <img src={"/left.svg"} width={isMobile ? 36 : 36} />
          </motion.div>
          {enterCallback && (
            <motion.div
              className="flex flex-col items-center mx-8"
              //animate={controls}
              initial={{ opacity: 1 }}
            >
              <button
                style={{
                  backgroundColor: color || "#dcc447",
                  border: 0,
                  padding: "8px ",
                  //boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                }}
                className="w-24 h-24 rounded-full text-white text-2xl "
                onClick={() => {
                  if (enterCallback) {
                    enterCallback();
                  }
                }}
              >
                ENTRA
              </button>
            </motion.div>
          )}

          {homeCallback && (
            <motion.div
              className="flex flex-col items-center"
              //animate={controls}
              initial={{ opacity: 1 }}
            >
              <button
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  border: 0,
                  padding: "8px ",
                  //boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                }}
                className="w-40 text-white text-3xl h-18"
                onClick={() => {
                  if (homeCallback) {
                    homeCallback();
                  }
                }}
              >
                HOME
              </button>
            </motion.div>
          )}

          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 1.2 }}
            style={{
              pointerEvents: active ? "none" : "all",
              opacity: active ? 0 : 1,
              zIndex: 9999,
              cursor: "pointer",
            }}
            onClick={() => {
              nextCallback();
            }}
          >
            <img src={"/right.svg"} width={isMobile ? 36 : 36} />
          </motion.div>
        </div>
        <div className="font-sans text-white text-center mt-4 text-sm">
          Usa le frecce per scorrere i semi
          <br /> Tocca <span>ENTRA</span> per esplorarlo
        </div>
        <div>
          <svg
            width="526"
            height="46"
            viewBox="0 0 526 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "200px" }}
          >
            <path
              d="M25.979 38.3914C21.979 44.1414 13.529 46.2714 7.42895 41.9914C1.32895 37.7114 0.428953 29.0814 4.42895 23.3314C12.509 11.8714 31.029 -0.448647 35.129 2.42135C39.229 5.29135 33.999 26.9414 25.979 38.3914Z"
              fill={activeSeed === 0 ? "white" : "none"}
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M80.1289 38.3914C76.1289 44.1414 67.6789 46.2714 61.5789 41.9914C55.4789 37.7114 54.5789 29.0814 58.5789 23.3314C66.5789 11.8714 85.1389 -0.448647 89.2389 2.42135C93.3389 5.29135 88.1489 26.9414 80.1289 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 1 ? "white" : "none"}
            />
            <path
              d="M134.279 38.3914C130.279 44.1414 121.829 46.2714 115.729 41.9914C109.629 37.7114 108.729 29.0814 112.729 23.3314C120.729 11.8714 139.289 -0.448647 143.389 2.42135C147.489 5.29135 142.299 26.9414 134.279 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 2 ? "white" : "none"}
            />
            <path
              d="M188.429 38.3914C184.429 44.1414 175.979 46.2714 169.879 41.9914C163.779 37.7114 162.879 29.0814 166.879 23.3314C174.879 11.8714 193.439 -0.448647 197.549 2.42135C201.659 5.29135 196.449 26.9414 188.429 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 3 ? "white" : "none"}
            />
            <path
              d="M242.579 38.3914C238.579 44.1414 230.129 46.2714 224.029 41.9914C217.929 37.7114 217.029 29.0814 221.029 23.3314C229.029 11.8714 247.589 -0.448647 251.699 2.42135C255.809 5.29135 250.599 26.9414 242.579 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 4 ? "white" : "none"}
            />
            <path
              d="M296.729 38.3914C292.729 44.1414 284.279 46.2714 278.179 41.9914C272.079 37.7114 271.179 29.0814 275.179 23.3314C283.179 11.8714 301.739 -0.448647 305.849 2.42135C309.959 5.29135 304.749 26.9414 296.729 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 5 ? "white" : "none"}
            />
            <path
              d="M350.879 38.3914C346.879 44.1414 338.429 46.2714 332.329 41.9914C326.229 37.7114 325.329 29.0814 329.329 23.3314C337.329 11.8714 355.899 -0.448647 359.999 2.42135C364.099 5.29135 358.909 26.9414 350.879 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 6 ? "white" : "none"}
            />
            <path
              d="M405.039 38.3914C401.039 44.1414 392.589 46.2714 386.479 41.9914C380.369 37.7114 379.479 29.0814 383.479 23.3314C391.479 11.8714 410.049 -0.448647 414.149 2.42135C418.249 5.29135 413.059 26.9414 405.039 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 7 ? "white" : "none"}
            />
            <path
              d="M459.189 38.3914C455.189 44.1414 446.739 46.2714 440.629 41.9914C434.519 37.7114 433.629 29.0814 437.629 23.3314C445.629 11.8714 464.199 -0.448647 468.299 2.42135C472.399 5.29135 467.209 26.9414 459.189 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 8 ? "white" : "none"}
            />
            <path
              d="M513.339 38.3914C509.339 44.1414 500.889 46.2714 494.779 41.9914C488.669 37.7114 487.779 29.0814 491.779 23.3314C499.779 11.8714 518.349 -0.448647 522.449 2.42135C526.549 5.29135 521.359 26.9414 513.339 38.3914Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={activeSeed === 9 ? "white" : "none"}
            />
          </svg>
        </div>
      </div>

      <div
        className="absolute flex flex-col items-center left-2 md:left-4 top-2 cursor-pointer z-10"
        onClick={() => {
          setAboutOpen(true);
          setTimeout(() => {
            controls.start({
              opacity: 1,
              transition: {
                duration: 1,
              },
            });
          }, 200);
        }}
      >
        <motion.img
          src={aboutSvg}
          width={isMobile ? 24 : 32}
          style={{
            opacity: 1,
          }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 1.2 }}
        />
        <div
          className="text-white font-sans mt-2"
          style={{
            fontSize: "11px",
          }}
        >
          ABOUT
        </div>
      </div>

      <div
        className="absolute flex flex-col items-center right-2 md:right-4 top-2 cursor-pointer z-10"
        onClick={toggleSound}
      >
        <motion.img
          src={soundSvg}
          width={isMobile ? 24 : 32}
          style={{
            opacity: soundActive ? 1 : 0.5,
          }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 1.2 }}
        />
        <div
          className="text-white font-sans mt-2"
          style={{
            fontSize: "11px",
          }}
        >
          {soundActive ? "SUONO ATTIVO" : "SUONO DISATTIVO"}
        </div>
      </div>

      {aboutOpen && (
        <motion.div
          className="absolute text-white px-4 md:px-16 py-8 md:py-16 right-0 left-0 top-0 cursor-pointer "
          style={{
            backgroundColor: "#E3C089",
            zIndex: aboutOpen ? 50 : 0,
          }}
          animate={controls}
          initial={{ opacity: 0 }}
        >
          <div className="max-w-3xl mx-auto min-h-screen">
            <div className="flex justify-center pb-8">
              <img src={logo} width={isMobile ? 160 : 200} />
            </div>
            <p className="font-sans w-ful border-white border-b pb-8">
              INTERSPECIES è il risultato di un processo d’arte partecipata che
              ha coinvolto il quartiere di Valtesse Sant’Antonio Valverde. Due
              le azioni che hanno reso protagonisti i cittadini: intrecciare e
              seminare, gesti antichi, atti di pazienza e di speranza. Dieci i
              semi che gli abitanti hanno seminato nel loro quartiere, affidando
              ad ognuno di essi una parola: relazioni, memoria, sogni, macerie,
              cura, eredità, partecipazione, natura, fragilità, futuro. Dieci
              parole/seme da affidare alla terra e da coltivare perché producano
              i frutti migliori. Ogni seme è ricoperto da centinaia di pezzi di
              stoffa, donati dai cittadini e raccolti di casa in casa. Mani
              pazienti li hanno tagliati, dipinti e ricamati. Mani abili li
              hanno intrecciati, dando forma e colore a dieci grandi semi. Esito
              di interconnessioni tra uomini, memorie, immaginazioni e paesaggi,
              questi semi ibridi custodiscono più di mille parole, che
              riposeranno nella terra. Da essi germinerà un nuovo vocabolario
              per il quartiere, frutto della commistione dei pensieri di
              individui di origini, religioni e culture diverse. Frutto non
              garantito e – forse - non immediato. Ma se non si semina, di certo
              non ci sarà raccolto.
            </p>
            <h3 className="font-sans font-bold py-8">
              PERCORSO REALIZZAZIONE OPERA
            </h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              Il percorso di creazione di “Interspecies”, grandi semi metallici
              intrecciati di stoffe, posizionati sulla Green Way a Valverde, ha
              preso il via dopo un lungo lavoro sottotraccia, iniziato da TTB e
              HG80 nel quartiere di Valtesse Sant’Antonio Valverde nel corso del
              2021, e da una forte attivazione della Rete di Quartiere - I
              Colori della Morla. Un lavoro di tessitura di relazioni, prima
              ancora che di stoffe. In una prima fase, durante un fine settimana
              di febbraio, il TTB, insieme a narratori, musicisti e attori del
              quartiere, è passato “di casa in casa” barattando un canto, una
              storia, un frammento spettacolare con un pezzo di stoffa che i
              residenti hanno voluto donare. In seguito i tessuti sono stati
              portati nel laboratorio allestito nell’ex asilo di Valverde per
              essere tagliati e suddivisi per colore da un gruppo di operose
              volontarie. Ma i cittadini, oltre alle stoffe, hanno donato parole
              per loro importanti o significative, per dare vita al “Vocabolario
              del Quartiere”: una raccolta di termini che compongono il lessico
              simbolico e reale, personale e condiviso della comunità, un
              raccoglitore delle voci degli abitanti, un insieme di storie,
              pensieri, ricordi, impressioni, visioni di una realtà complessa e
              vitale come quella di un quartiere. Le parole donate sono state
              risultato di interviste, laboratori nelle scuole o nei parchi,
              chiacchierate al bar e dal parrucchiere, momenti informali di
              incontro, cene, aperitivi e pranzi nelle case dei residenti. Sono
              state scritte sulle stoffe con diversi colori e diverse
              calligrafie, sono state ricamate da mani differenti su ogni
              singolo pezzo di tessuto e intrecciate tra loro per dare forma a
              “Interspecies”, l’installazione artistica di Lisa Martignoni.
            </p>
            <h3 className="font-sans font-bold py-8">L'ARTISTA</h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              Lisa Martignoni è nata in Svizzera nel 1994. Diplomata come
              Textile Designer al CSIA di Lugano si è laureata al corso
              triennale di Arti visive presso l’Accademia di Belle Arti di
              Bologna, completando il percorso formativo con la laurea
              Magistrale in Arti Visive presso la stessa Accademia. Dal 2017 le
              sue opere sono state selezionate in contest internazionali (Young
              Fiber Contest Chieri, International Biennial Exhibition of Mini
              Textile Art Scythia Ucraina) e in mostre collettive in Italia ed
              all’estero.
            </p>
            <h3 className="font-sans font-bold py-8">
              {" "}
              IL MANTELLO DI ARLECCHINO
            </h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              “Il Mantello di Arlecchino. Dalla circonferenza al centro”, ideato
              da TTB Teatro tascabile di Bergamo con Hg80 Impresa sociale,
              sostenuto dall’Assessorato alla Cultura, condiviso dalle Reti di
              quartiere e finanziato da Fondazione Cariplo, è un progetto che
              nasce dalla volontà di riattivare legami sociali e ricomporre,
              attraverso un'azione culturale partecipata, i micro-tessuti
              cittadini in un dialogo tra centro e periferie. Perni del progetto
              sono le pratiche legate al baratto culturale e alla partecipazione
              diretta dei cittadini. Residenti, associazioni, gruppi dei
              quartieri di Valtesse Sant’Antonio Valverde e Colognola sono stati
              chiamati a partecipare alla realizzazione di due opere d’arte
              tessile, alle quali ognuno contribuisce con una pezza di tela
              colorata; di due opere d’arte digitale ispirate alle opere visuali
              e alla raccolta di racconti, disegni e parole dei cittadini; di
              due settimane di festa durante le quali, oltre alle azioni
              spettacolari o musicali dei Festival diffusi realizzate dagli
              artisti stessi dei quartieri, verranno messe in scena, in una
              cornice spettacolare, le differenti abilità dei quartieri; e
              infine Dalla circonferenza al centro: portare il lavoro svolto nei
              e dai quartieri nei luoghi centrali della cultura.
            </p>
            <div className="flex justify-center">
              <div
                onClick={() => {
                  controls.start({
                    opacity: 0,
                    transition: {
                      duration: 1,
                    },
                  });

                  setTimeout(() => {
                    setAboutOpen(false);
                  }, 1000);
                }}
                className="p-6 cursor-pointer bg-white rounded-3xl h-12 my-8 text-black flex justify-center items-center text-2xl"
              >
                TORNA ALLA HOME
              </div>
            </div>
            <div className="flex justify-center  text-black">
              <a
                href="https://www.mantellodiarlecchino.it/"
                target="_blank"
                className="font-sans hover:underline"
              >
                Visita il sito del Mantello di Arlecchino
              </a>
            </div>
            <div className="flex justify-center py-8">
              <img src={logoMantelloSvg} width={isMobile ? 160 : 200} />
            </div>

            <div
              className="absolute flex flex-col items-center right-2 md:right-4 top-2 cursor-pointer z-10"
              onClick={() => {
                controls.start({
                  opacity: 0,
                  transition: {
                    duration: 1,
                  },
                });

                setTimeout(() => {
                  setAboutOpen(false);
                }, 1000);
              }}
            >
              <img
                src={closeSvg}
                width={isMobile ? 24 : 32}
                style={{
                  opacity: soundActive ? 1 : 0.5,
                }}
              />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default AppControllers;
